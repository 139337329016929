import { Avatar, Button, StarIcon, Text, toast } from "@olivahealth/oli-ui";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import React, { useState } from "react";
import { SpeechStarIcon } from "@olivahealth/oli-ui/src/atoms/Icons/SpeechStar";
import { graphql, useMutation } from "react-relay";
import logger from "@olivahealth/logger/client";
import Image from "../../components/common/Image";
import useTranslation from "../../hooks/useTranslation";
import useSmallScreenDetection from "../../hooks/useSmallScreenDetection";
import { PostSessionSatisfactionSurveySubmitMutation } from "./__generated__/PostSessionSatisfactionSurveySubmitMutation.graphql";
import { PostSessionSatisfactionSurveySkipMutation } from "./__generated__/PostSessionSatisfactionSurveySkipMutation.graphql";
import * as s from "./styles";

interface IProps {
  professionalName: string;
  professionalImage: string;
  variant?: "dark" | "light";
  sessionId: string;
  onSatisfactionSkipped?: () => void;
  onSatisfactionSubmitted?: () => void;
}

const SavePostSessionSatisfactionSurveyMutation = graphql`
  mutation PostSessionSatisfactionSurveySubmitMutation(
    $sessionId: ID!
    $score: Int!
  ) {
    savePostSessionSatisfactionSurvey(sessionId: $sessionId, score: $score) {
      ... on PostSessionSatisfactionSurveySaved {
        success
      }
      ... on ForbiddenError {
        reason
      }
    }
  }
`;

const SkipPostSessionSatisfactionSurveyMutation = graphql`
  mutation PostSessionSatisfactionSurveySkipMutation($sessionId: ID!) {
    skipPostSessionSatisfactionSurvey(sessionId: $sessionId) {
      ... on PostSessionSatisfactionSurveySkipped {
        success
      }
      ... on ForbiddenError {
        reason
      }
    }
  }
`;

export default function PostSessionSatisfactionSurvey({
  professionalName,
  professionalImage,
  variant = "dark",
  sessionId,
  onSatisfactionSkipped,
  onSatisfactionSubmitted,
}: IProps): JSX.Element {
  const { t } = useTranslation("postSessionSatisfaction");
  const isMobile = useSmallScreenDetection();
  const [rating, setRating] = useState<number | null>(null);
  const isLight = variant === "light";

  const [saveMutation, isSubmittingSatisfaction] =
    useMutation<PostSessionSatisfactionSurveySubmitMutation>(
      SavePostSessionSatisfactionSurveyMutation,
    );
  const [skipMutation, isSkippingSatisfaction] =
    useMutation<PostSessionSatisfactionSurveySkipMutation>(
      SkipPostSessionSatisfactionSurveyMutation,
    );

  const submitSatisfactionSurvey = () => {
    if (!rating) {
      return;
    }

    saveMutation({
      variables: { sessionId, score: rating },
      onCompleted: () => {
        if (onSatisfactionSubmitted) {
          onSatisfactionSubmitted();
        }
      },
      onError: (error) => {
        logger.error(
          "surveys/postSessionSatisfaction",
          "Unknown error completing the session satisfaction.",
          {
            rating,
            sessionId,
            error,
          },
        );
        if (onSatisfactionSubmitted) {
          onSatisfactionSubmitted();
        }
      },
    });
    toast({
      body: t("successToast"),
      variant: "success",
    });
  };
  const skipSatisfactionSurvey = () => {
    skipMutation({
      variables: { sessionId },
      onCompleted: () => {
        if (onSatisfactionSkipped) {
          onSatisfactionSkipped();
        }
      },
      onError: (error) => {
        logger.error(
          "surveys/postSessionSatisfaction",
          "Unknown error skipping the session satisfaction.",
          {
            sessionId,
            error,
          },
        );
        if (onSatisfactionSkipped) {
          onSatisfactionSkipped();
        }
      },
    });
  };

  const getRatingTitle = () => {
    const titles = {
      1: "ratingTitle.terrible",
      2: "ratingTitle.poor",
      3: "ratingTitle.okay",
      4: "ratingTitle.good",
      5: "ratingTitle.excellent",
    };
    return t(rating !== null ? titles[rating] : "ratingTitle.default");
  };

  const handleStarClick = (starRating: number) => {
    setRating((prev) => (prev === starRating ? null : starRating));
  };

  return (
    <div className={s.postSessionSatisfactionWrapper(isLight)}>
      {!isLight && (
        <Text variant="body" weight="bold">
          {t("header")}
        </Text>
      )}
      <div className="relative">
        {professionalImage ? (
          <Image
            src={professionalImage}
            alt={t("imageAlt")}
            className="rounded-full object-cover"
            width={isMobile || isLight ? 64 : 96}
            height={isMobile || isLight ? 64 : 96}
          />
        ) : (
          <Avatar
            size={isMobile || isLight ? "md" : "l"}
            label={professionalName}
          />
        )}
        <SpeechStarIcon
          color={tokens.colors["accent-blue"][400]}
          size={isMobile || isLight ? 26 : 36}
          className="absolute -top-2 -right-2 z-10"
        />
      </div>
      <div className="flex flex-col gap-6 items-center ">
        <Text weight="bold" align="center" size={isLight ? "2xl" : "3xl"}>
          {isLight
            ? t("resendTitle", { professionalName: professionalName })
            : t("title", { professionalName: professionalName })}
        </Text>
        <div className="w-[22rem]">
          <Text
            variant="body"
            align="center"
            size="base"
            color={isLight ? "primary-light" : "white"}
          >
            {t("subtitle")}
          </Text>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:gap-8">
        <Text size={isLight ? "base" : "xl"} variant="body" align="center">
          {getRatingTitle()}
        </Text>
        <div className="flex items-center gap-6 md:gap-8">
          {[1, 2, 3, 4, 5].map((star) => (
            <button key={star} onClick={() => handleStarClick(star)}>
              <StarIcon
                key={star}
                size={isMobile || isLight ? 26 : 32}
                color={
                  rating !== null && rating >= star
                    ? tokens.colors["status-yellow"][500]
                    : isLight
                      ? "black"
                      : "white"
                }
                fill={
                  rating !== null && rating >= star
                    ? tokens.colors["status-yellow"][500]
                    : "transparent"
                }
              />
            </button>
          ))}
        </div>
      </div>
      <div className={s.postSessionSatisfactionButtonWrapper(isLight)}>
        <Button
          disabled={!rating || isSubmittingSatisfaction}
          width={"full"}
          variant={isLight ? "success" : "green"}
          onClick={submitSatisfactionSurvey}
        >
          {t("buttonLabel")}
        </Button>
        {isLight && (
          <Button
            disabled={isSkippingSatisfaction}
            variant="icon"
            justify="center"
            onClick={skipSatisfactionSurvey}
          >
            {t("buttonSkipLabel")}
          </Button>
        )}
      </div>
    </div>
  );
}
