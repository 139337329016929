/**
 * @generated SignedSource<<702168bebb2dbbc107a2baaac5cab94c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PostSessionSatisfactionSurveySkipMutation$variables = {
  sessionId: string;
};
export type PostSessionSatisfactionSurveySkipMutation$data = {
  readonly skipPostSessionSatisfactionSurvey: {
    readonly reason?: string;
    readonly success?: boolean;
  };
};
export type PostSessionSatisfactionSurveySkipMutation = {
  response: PostSessionSatisfactionSurveySkipMutation$data;
  variables: PostSessionSatisfactionSurveySkipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sessionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sessionId",
    "variableName": "sessionId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "success",
      "storageKey": null
    }
  ],
  "type": "PostSessionSatisfactionSurveySkipped",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "type": "ForbiddenError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostSessionSatisfactionSurveySkipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "skipPostSessionSatisfactionSurvey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostSessionSatisfactionSurveySkipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "skipPostSessionSatisfactionSurvey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "80a10c279026746e22c836079a270e03",
    "id": null,
    "metadata": {},
    "name": "PostSessionSatisfactionSurveySkipMutation",
    "operationKind": "mutation",
    "text": "mutation PostSessionSatisfactionSurveySkipMutation(\n  $sessionId: ID!\n) {\n  skipPostSessionSatisfactionSurvey(sessionId: $sessionId) {\n    __typename\n    ... on PostSessionSatisfactionSurveySkipped {\n      success\n    }\n    ... on ForbiddenError {\n      reason\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a25d4a16c70939e515c6f95e8a543646";

export default node;
