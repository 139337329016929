import React from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function SpeechStarIcon({
  color,
  size,
  className,
}: IconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 36 36" fill="none" size={size} className={className}>
      <path
        d="M32 16C32 7.16344 24.8366 0 16 0C7.16345 0 1.90735e-06 7.16344 1.90735e-06 16C1.90735e-06 24.8366 7.16345 32 16 32H32V16Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3438 20.2585L19.3433 20.2582L19.3438 20.2585ZM16.6299 10.565C16.3821 10.0278 15.6186 10.0278 15.3708 10.565L14.1785 13.1498C14.0776 13.3687 13.87 13.5195 13.6306 13.5479L10.8039 13.883C10.2164 13.9527 9.98047 14.6789 10.4148 15.0806L12.5047 17.0132C12.6817 17.1769 12.7609 17.4208 12.714 17.6573L12.1592 20.4492C12.0439 21.0295 12.6616 21.4783 13.1779 21.1893L15.6617 19.799C15.8721 19.6812 16.1286 19.6812 16.339 19.799L18.8228 21.1893C19.3391 21.4783 19.9568 21.0295 19.8415 20.4492L19.2868 17.6573C19.2398 17.4208 19.319 17.1769 19.4961 17.0132L21.5859 15.0806C22.0202 14.6789 21.7843 13.9527 21.1968 13.883L18.3701 13.5479C18.1307 13.5195 17.9232 13.3687 17.8222 13.1498L16.6299 10.565Z"
        fill="#1C1831"
      />
    </SvgIcon>
  );
}
