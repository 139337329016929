import { Dialog } from "@olivahealth/oli-ui";
import React, { useState, useEffect } from "react";
import { useUserData } from "../../services/contexts/UserDataContext";
import PostSessionSatisfaction from "./index";

export default function PostSessionSatisfactionDialog() {
  const { data: user, refetchData } = useUserData();
  const [showModal, setShowModal] = useState(false);

  const sessionId = user?.surveyEmployeePostSessionSatisfaction?.sessionId;
  const professional =
    user?.surveyEmployeePostSessionSatisfaction?.professional;

  useEffect(() => {
    if (sessionId) {
      setShowModal(true);
    }
  }, [sessionId]);

  if (!showModal || !sessionId || !professional) {
    return null;
  }

  return (
    <Dialog
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      size="lg"
      showCloseButton={false}
    >
      <PostSessionSatisfaction
        onSatisfactionSkipped={() => setShowModal(false)}
        onSatisfactionSubmitted={async () => {
          setShowModal(false);
          await refetchData();
        }}
        professionalName={professional.name}
        professionalImage={professional.clinicalProfile?.image || ""}
        variant="light"
        sessionId={sessionId}
      />
    </Dialog>
  );
}
